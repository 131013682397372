import React, { useEffect, useRef, useState } from "react";
import DateRangePicker from "./dateRangePicker";
import dateRangeStyle from "./dateRangeParent.module.css";

const handleFormattedDates = (formattedStartDate, formattedEndDate) => {
  // Use the formatted start and end dates here
//  console.log("Formatted Start Date in Parent:", formattedStartDate);
 // console.log("Formatted End Date in Parent:", formattedEndDate);
};

const formatDate = (dateObject) => {
  const year = dateObject.$y;
  const month = (dateObject.$M + 1).toString().padStart(2, "0");
  const day = dateObject.$D.toString().padStart(2, "0");
  // Create a string in the "YYYY-MM-DD" format
  return `${year}-${month}-${day}`;
};

const DatePickParent = ({
  getDateRangeState,
  applyFunc,
  cancelFunc,
  containerWidth,
  textBoxWidth,
  weekStartDay,
  dateFormatShow,
}) => {
  const [open, setOpen] = useState(false);
  const [focusState, setFocusState] = useState(false);
  const parentRef = useRef(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [loading, setLoading] = useState(true); // Loading state for asynchronous operations

  const togglePicker = () => {
    setOpen((prev) => !prev);
    setFocusState((prev) => !prev);
  };

  useEffect(() => {
    if (startDate && endDate) {
      const formattedStartDate = formatDate(startDate);
      const formattedEndDate = formatDate(endDate);

    //  console.log("Formatted Start Date in Parent:", formattedStartDate);
   //   console.log("Formatted End Date in Parent:", formattedEndDate);

      localStorage.setItem("selectedStartDate", formattedStartDate);
      localStorage.setItem("selectedEndDate", formattedEndDate);

      sessionStorage.setItem("selectedStartDate", formattedStartDate);
sessionStorage.setItem("selectedEndDate", formattedEndDate);

      const path = `/Browseshops?start=${formattedStartDate}&end=${formattedEndDate}`;
      window.location.href = path;
    }

    if (getDateRangeState) {
      getDateRangeState({ startDate, endDate });
    }
  }, [startDate, endDate, getDateRangeState]);

  useEffect(() => {
    if (!getDateRangeState) {
      return;
    }
    if (startDate) {
      getDateRangeState({ startDate, endDate });
    } else {
      getDateRangeState(null);
    }
  }, [startDate]);

  useEffect(() => {
    // Simulate loading for 2 seconds
    const timeoutId = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timeoutId);
  }, []);

  const date1 =  sessionStorage.getItem("selectedStartDate");
  const date2 = sessionStorage.getItem("selectedEndDate");
 
  return (
    <div style={{ width: textBoxWidth ? textBoxWidth : "400px" }} className={`${dateRangeStyle.mainContainer}`}>
      <div
        ref={parentRef}
        className={`${dateRangeStyle.textField} ${focusState ? dateRangeStyle.focusedBorder : ""}`}
        onClick={togglePicker}
      >
        <div className={`${dateRangeStyle.dateView}`}>
          {loading ? (
            "Loading..."
          ) : (
            date1 && date2 ? (
              `${date1} - ${date2}`
            ) : (
              "Select the dates"
            )
          )}
        </div>

        <div className={`${dateRangeStyle.calender}`}>
          <img src="/calender.jpg" alt="" width="32px" height="32px" style={{ marginTop: "15px", marginRight: "10px" }} />
        </div>
      </div>
      {open && (
        <DateRangePicker
          containerWidth={containerWidth ? containerWidth : "650px"}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          openState={setOpen}
          focusState={setFocusState}
          parentRef={parentRef}
          applyFunc={applyFunc}
          cancelFunc={cancelFunc}
          weekStartDay={weekStartDay}
          weekSize="3"
        />
      )}
    </div>
  );
};

export default DatePickParent;
