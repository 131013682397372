import React, { useEffect, useState, useCallback } from 'react';
import "./Form1.scss";
import { Link } from "react-router-dom"
import axios from 'axios';
import { useDropzone } from 'react-dropzone';
import AWS from 'aws-sdk';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Form14 = (props) => {
  const graphqlEndpoint = process.env.REACT_APP_GRAPHQL_ENDPOINT;
  const accessKeyId = process.env.REACT_APP_AWS_ACCESS_KEY_ID;
  const secretAccessKey = process.env.REACT_APP_AWS_SECRET_ACCESS_KEY;
  const [uploadStatus, setUploadStatus] = useState('');
  const [newImageUrls, setNewImageUrls] = useState([]);

  const onDrop = useCallback(async (acceptedFiles) => {
    const s3 = new AWS.S3({
      region: 'us-east-1',
      accessKeyId: accessKeyId,
      secretAccessKey: secretAccessKey ,
    });

    try {
      const uploadPromises = acceptedFiles.map(async (file) => {
        const params = {
          Bucket: 'imageofstore',
          Key: file.name,
          Body: file,
          ACL: 'public-read', // Adjust the ACL based on your requirements
        };

        const response = await s3.upload(params).promise();
     // log('Upload response:', response);

        // Extract the URL from the response and log it
        const imageUrl = response.Location;
  //      console.log('Image URL:', imageUrl);

        return imageUrl;
      });

      const newImageUrls = await Promise.all(uploadPromises);

   //   console.log('All images uploaded:', newImageUrls);
      setNewImageUrls(newImageUrls);
      setUploadStatus('Upload successful!');
    } catch (error) {
      console.error('Upload error:', error);
      setUploadStatus('Upload failed.');
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, multiple: true });

  const [formData, setFormData] = useState({ storeId: localStorage.getItem('register_store') || '', listOfUrl: newImageUrls });

  useEffect(() => {
    const handleStorageChange = () => {
      const storeId = localStorage.getItem('register_store');
      setFormData((prevFormData) => ({ ...prevFormData, storeId: storeId || '' }));
    };
    window.addEventListener('storage', handleStorageChange);
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [newImageUrls]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Parse storeId to Long type
      const storeIdAsLong = parseInt(formData.storeId, 10);
      const response = await axios.post(graphqlEndpoint, {
        query: `
          mutation SetStoreImage($listOfUrl: [String!], $storeId: Long!) {
            setStoreImage(storeId: $storeId, listOfUrl: $listOfUrl) {
              StoreName
            }
          }`,
        variables: {
          listOfUrl: newImageUrls, // Use newImageUrls here
          storeId: storeIdAsLong,
        },
      });

      setSaveClicked(true);
      toast.success('Images saved successfully!');
    
      // Handle the response data
   //   console.log(response.data);
   //   console.log(response.data.data.setStoreImage); // prints StoreName
   
      setFormData({ storeId: props.register_store || "", listOfUrl: [] });
    } catch (error) {
      console.log(error);
    }
  };
  const [saveClicked, setSaveClicked] = useState(false);
  const handleNextClick = () => {
    if (!saveClicked) {
      // Show toast notification if "Next Page" is clicked before "Save Images"
      toast.error('Please click "Save Images" first.');
    } else {
      // Navigate to the next page
      // You may replace this with your actual route
      window.location.href = '/Listyourshelf';
    }
  }; 
  return (
    <div className="form1fourthblock">
      <div className="fourthblockimageSection1">
        <div className="fourthblockstore">
          <div className="fourthblockstoreImages">Store Images</div>
          <div className="fourthblocktellUsAbout1">
            Tell us about your Store. These specifics will help us figure out the best clients for you
          </div>
        </div>
        <div {...getRootProps()} className={`dropzone ${isDragActive ? 'active' : ''}`}>
          <input {...getInputProps()} />
          <div className="fourthblockuploadSection">
            <div className="fourthblockuploadfiles">
              <img className="fourthblockocticonupload161" alt="" src="https://i.postimg.cc/BQ7jZ1hX/Frame-3508.png" />
              <div className="fourthblocktextcontent">
                <div className="fourthblockclickHereToContainer">
                  <span className="fourthblocktoUploadOr"> to upload or drop media here</span>
                  <div className="fourthblocktoUploadOr"> Please upload atleast 2images</div>
                </div>
              </div>
            </div>
          </div>
        </div>
       
        <div>Loading Image...... {uploadStatus}</div>

        <div className="imgurlbutton">
      <button type="submit" id="buttonk" onClick={handleSubmit}>
        Save Images
      </button>
      <Link to="#" id="buttonimg" onClick={handleNextClick}>
        Next Page
      </Link>
      <ToastContainer />
    </div>


       

      </div>
    </div>
  );
};

export default Form14;
