import React, { useEffect , useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import "./Form3.scss"
const Form3 = (props) => {
  const graphqlEndpoint = process.env.REACT_APP_GRAPHQL_ENDPOINT;
  const [formData, setFormData] = useState({
    id: props.storeId  || "",
    //id : 33  ,
    accountNumber: '',
    accountName: '' ,
    sortCode: '',
  });
     
  useEffect(() => {
    setFormData((prevFormData) => ({ ...prevFormData, id: props.storeId || '' }));
  }, [props.storeId]); 

      const handleSubmit = async (e) => {
        e.preventDefault();
    
        try {
          // Make a POST request to the GraphQL endpoint
          const response = await axios.post(graphqlEndpoint, {
            query: `
              mutation storeBankInformation(
                $id: Long!,
                  
                       $accountNumber: String!,
                       $accountName: String!,
                       $sortCode: String!,
                     
    
             
              ) {
                 bankInformation(
                  id: $id,
                  accountNumber: $accountNumber,
                  accountName: $accountName,
                  sortCode: $sortCode,
                
                )
              }
            `,
            variables: {
              id: parseInt(formData.id), // Assuming id is a Long on the backend
              accountName: formData.accountName,
              accountNumber: formData.accountNumber ,  // Convert to Long
              sortCode: formData.sortCode,
             
            },
          });
    
          // Handle the response data
      //    console.log(response.data);
    
          // Reset the form after successful submission
          setFormData({
            id: '',
            accountNumber: '',
            accountName: '', // Assuming averageOrderValue is a Long on the backend
            sortCode: '',
          
          });
        } catch (error) {
          // Handle errors
          console.error(error);
        }
      };


  return (
    
            <div className="bankstoreParent">
                <div className="bankstore">
                    <div className="bankbankInformation">Bank Information</div>
                    <div className="banktellUsYour1">Tell us your bank information. This will help us transfer your funds correctly</div>
                </div>
                <div className="bankform">
                <form onSubmit={handleSubmit}>
                    <div className="banktextField">
                    
                        <div className="banklabelWrapper">
                            <div className="banklabel">Account name</div>
                        </div>
                        <div className="bankinput">
                        <input
          type="text"
          id = "bankinput"
         placeholder="David Smith"
          value={formData.accountName}
          onChange={(e) => setFormData({ ...formData, accountName: e.target.value })}
        />
                        </div>
                    </div>
                    <div className="banktextFieldParent">

                    <div className="banktextField">
                        <div className="banklabelWrapper">
                            <div className="banklabel">Sort Code</div>
                        </div>
                        <div className="bankinput">
                        <input
          type="text"
          id = "bankinput"
          placeholder='00-00-00'
          value={formData.sortCode}
          onChange={(e) => setFormData({ ...formData, sortCode: e.target.value })}
        />
                        </div>
                    </div>

 {/*}
                        <div className="banktextField1">
                            <div className="banklabelContainer">
                                <div className="banklabel">Sort code</div>
                            </div>
                            <div className="bankinput">
                         
                            </div>
                        </div>
                        <div className="banktextField2">
                            <div className="bankinput2">
                                <div className="bankplaceholder">Enter code</div>
                            </div>
                        </div>
                        <div className="banktextField2">
                            <div className="bankinput2">
                                <div className="bankplaceholder">Enter code</div>
                            </div>
                        </div>
                         {*/}
                    </div>


 

                    <div className="banktextField">
                        <div className="banklabelWrapper">
                            <div className="banklabel">Account number*</div>
                        </div>
                        <div className="bankinput">
                        <input
          type="text"
          id = "bankinput"
          placeholder='XX987XX0'
          value={formData.accountNumber}
          onChange={(e) => setFormData({ ...formData, accountNumber: e.target.value })}
        />
                        </div>
                    </div>
                    <div className="banktextField">
                        <div className="banklabelWrapper">
                            <div className="banklabel">Confirm account number*</div>
                        </div>
                        <div className="bankinput">
                        <input
          type="text"
          id = "bankinput"
          placeholder='XX987XX0'
          value={formData.accountNumber}
          onChange={(e) => setFormData({ ...formData, accountNumber: e.target.value })}
        />
                        </div>
                    </div>
                    
                    <div className="buttonb"> 
        				 <button type="submit"  id ="buttonb" >Save</button>
                <Link to = "/Listyourshelf"> <button id = "buttonb" > buttton</button>  </Link>
      		     	</div>


                  </form>
                </div>
              
            </div>);
    };
  

export default Form3
