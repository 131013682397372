import dayjs from "dayjs";
import React, { useState, useRef, useMemo, useEffect } from "react";
import dateRangeStyle from "./dateRangePicker.module.scss";

const Calender2 = ({
  weekStartDay,
  weekSize,
  containerWidth,
  setStartDate,
  setEndDate,
  openState,
  parentRef,
  focusState,
  applyFunc,
  cancelFunc,
}) => {
  const handleApply = () => {
    window.location.reload();
   }

  const [firstDate, setFirstDate] = useState(dayjs());
  const [secondDate, setSecondDate] = useState(dayjs().add(1, "month"));

  const [hoverDate, setHoverDate] = useState(null);
  const [selectedFirstDate, setSelectedFirstDate] = useState(null);
  const [selectedLastDate, setSelectedLastDate] = useState(null);
  const [selectedDaysCount, setSelectedDaysCount] = useState(0);

  const containerRef = useRef(null);

  const getDate = (first, second) => {
    const difference = first + 1 - (second + 1);
    if (difference === 0) {
      return 0;
    } else if (difference > 0) {
      return difference;
    }
    return difference + 7;
  };
  
  const calendarGridValue = (year, month) => {
    const weekDayStart =
      weekStartDay && !isNaN(parseInt(weekStartDay, 10))
        ? parseInt(weekStartDay, 10)
        : 0;
    const firstDayOfMonth = dayjs().year(year).month(month).startOf("month");
    const firstCalenderDay = firstDayOfMonth.subtract(
      getDate(firstDayOfMonth.day(), weekDayStart),
      "day"
    );

    const dayList = [];
    let loopDate = firstCalenderDay;
    for (let i = 0; i < 42; i++) {
      dayList.push(loopDate);
      loopDate = loopDate.add(1, "day");
    }
    return dayList;
  };

  const changePreviousYear = () => {
    setFirstDate((prev) => prev.subtract(1, "month"));
    setSecondDate((prev) => prev.subtract(1, "month"));
  };

  const changeNextYear = () => {
    setFirstDate((prev) => prev.add(1, "month"));
    setSecondDate((prev) => prev.add(1, "month"));
  };

  const calenderClick = (date, calenderSide) => {
 
    if (!date.isSame(firstDate, "Month") && calenderSide === "right") {
      return;
    }
    if (!date.isSame(secondDate, "Month") && calenderSide === "left") {
      return;
    }
    if (selectedLastDate !== null) {
      setSelectedLastDate(null);
      setSelectedFirstDate(date);
      return;
    }
    const count = dayjs(selectedLastDate).diff(selectedFirstDate, 'day') + 1;
    setSelectedDaysCount(count);
    if (selectedFirstDate !== null) {
      if (selectedFirstDate.isSame(date, "day")) {
        return;
      }
      if (selectedFirstDate.isBefore(date)) {
        setSelectedLastDate(date);
      } else {
        setSelectedFirstDate(date);
      }
      return;
    }
    setSelectedFirstDate(date);
   
  };
 
  const getColor = (date) => {
    if (date.isSame(selectedFirstDate, "day")) {
      return dateRangeStyle.calenderCellStart;
    }
    if (date.isSame(selectedLastDate, "day")) {
      return dateRangeStyle.calenderCellEnd;
    }
    if (selectedFirstDate !== null && selectedLastDate !== null) {
      if (date.isAfter(selectedFirstDate) && date.isBefore(selectedLastDate)) {
        return dateRangeStyle.calenderCellInbetween;
      }
    }
    if (selectedFirstDate !== null && selectedLastDate === null) {
      if (date.isAfter(selectedFirstDate) && date.isBefore(hoverDate)) {
        return dateRangeStyle.calenderCellInbetween;
      }
    }
    return dateRangeStyle.calenderCellNormal;
  };


  const handleClearDates = () => {
    setSelectedFirstDate(null);
    setSelectedLastDate(null);
    setSelectedDaysCount(0);
    localStorage.removeItem('daysCount')
      window.location.reload();
  };

 

 

 useEffect(() => {
  if (selectedFirstDate !== null && selectedLastDate !== null) {
    const count = dayjs(selectedLastDate).diff(selectedFirstDate, 'day') + 1;
    setSelectedDaysCount(count);

    const startDateString = selectedFirstDate.format("YYYY-MM-DD");
    const endDateString = selectedLastDate.format("YYYY-MM-DD");

    // Store information in local storage
    localStorage.setItem('selectedStartDate', startDateString);
    localStorage.setItem('selectedEndDate', endDateString);
    localStorage.setItem('daysCount', count.toString());

  //  console.log("Selected Start Date in sdp:", startDateString);
   // console.log("Selected End Date in sdp:", endDateString);
   // console.log("Selected Days Count:", count);
  
  }
}, [selectedFirstDate, selectedLastDate, selectedDaysCount]); 

// console.log("boom boom",localStorage)

  const getWeekDays = useMemo(() => {
    // Calculate and return the week days
    const startDayOfWeek =
      weekStartDay && !isNaN(parseInt(weekStartDay, 10))
        ? parseInt(weekStartDay, 10)
        : 0;
    const daysOfWeek = [];
    for (let i = startDayOfWeek; i < startDayOfWeek + 7; i++) {
      const dayName = dayjs().day(i % 7).format("dddd");
      daysOfWeek.push(dayName);
    }
    return daysOfWeek;
  }, [weekStartDay]);
  
  const getCalenderGridFirst = useMemo(() => {
    // Calculate and return the calendar grid for the first month
    return calendarGridValue(firstDate.year(), firstDate.month());
  }, [weekStartDay, firstDate]);
  
  const getCalenderGridSecond = useMemo(() => {
    // Calculate and return the calendar grid for the second month
    return calendarGridValue(secondDate.year(), secondDate.month());
  }, [weekStartDay, secondDate]);

  return (
    <div className={dateRangeStyle.calender}>
      <div
        style={{ width: containerWidth }}
        ref={containerRef}
        className={dateRangeStyle.dateRangeContainer}
      >
        <div className={dateRangeStyle.topcalender}>
      <div className={dateRangeStyle.topcalenderheadline}>Book Shelf for {selectedDaysCount} days</div>
          <div className={dateRangeStyle.topcalenderclear} onClick={handleClearDates}>
        Clear Dates
          </div>
        </div>

        <div className={dateRangeStyle.calenderContainer}>
          <div className={dateRangeStyle.rightCalender}>
            <div className={dateRangeStyle.headerCalender}>
              <div
                className={dateRangeStyle.nextButton}
                onClick={changePreviousYear}
              >
                {"<"}
              </div>
              <div className={dateRangeStyle.yearMonthTitle}>
                {firstDate.format("MMMM YYYY")}
              </div>
            </div>
          
            <div className={dateRangeStyle.weekContainer}>
              {getWeekDays.map((weekDay) => (
                <div className={dateRangeStyle.weekCells} key={weekDay}>
                  {weekDay.slice(0, 3)}
                </div>
              ))}
            </div>
            <div className={dateRangeStyle.dateContainer}>
              {getCalenderGridFirst.map((dayValue) => (
                <div
                  key={dayValue.toString()}
                  className={`${dateRangeStyle.dayCells} ${getColor(dayValue)} ${
                    !dayValue.isSame(firstDate, "Month") &&
                    dateRangeStyle.calenderCellDisabled
                  }`}
                  onClick={() => calenderClick(dayValue, "right")}
                  onMouseEnter={() => {
                    if (dayValue.isSame(firstDate, "Month")) {
                      setHoverDate(dayValue);
                    }
                  }}
                  onMouseLeave={() => {
                    if (dayValue.isSame(firstDate, "Month")) {
                      setHoverDate(null);
                    }
                  }}
                >
                  {dayValue.format("D")}
                </div>
              ))}
            </div>
          </div>
          <div className={dateRangeStyle.leftCalender}>
            <div className={dateRangeStyle.headerCalender}>
              <div className={dateRangeStyle.yearMonthTitle}>
                {secondDate.format("MMMM YYYY")}
              </div>
              <div className={dateRangeStyle.nextButton} onClick={changeNextYear}>
                {">"}
              </div>
            </div>
            <div className={dateRangeStyle.weekContainer}>
              {getWeekDays.map((weekDay) => (
                <div className={dateRangeStyle.weekCells} key={weekDay}>
                  {weekDay.slice(0, 3)}
                </div>
              ))}
            </div>
            <div className={dateRangeStyle.dateContainer}>
              {getCalenderGridSecond.map((dayValue) => (
                <div
                  key={dayValue.toString()}
                  className={`${dateRangeStyle.dayCells} ${getColor(dayValue)} ${
                    !dayValue.isSame(secondDate, "Month") &&
                    dateRangeStyle.calenderCellDisabled
                  }`}
                  onClick={() => calenderClick(dayValue, "left")}
                  onMouseEnter={() => {
                    if (dayValue.isSame(secondDate, "Month")) {
                      setHoverDate(dayValue);
                    }
                  }}
                  onMouseLeave={() => {
                    if (dayValue.isSame(secondDate, "Month")) {
                      setHoverDate(null);
                    }
                  }}
                >
                  {dayValue.format("D")}
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className={dateRangeStyle.calenderapply}  onClick = { handleApply} >
         Apply  </div>
      </div>
    </div>
  );
};

export default Calender2;
